// customize some Bootstrap variables
/*$primary: darken(#428bca, 20%);*/

// the ~ allows you to reference things in node_modules
/*@import "~bootstrap/scss/bootstrap";*/

/*body {
    background-color: lightgray;
}*/

@import '../vendor/simplebar/dist/simplebar.min.css';    
@import '../vendor/tiny-slider/dist/tiny-slider.css';    
@import '../vendor/drift-zoom/dist/drift-basic.min.css';
@import '../vendor/lightgallery/css/lightgallery-bundle.min.css';
@import '../cartzilla3/src/scss/theme.scss';
/*@import 'theme.min.css';*/