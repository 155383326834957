/*
 * Cartzilla | Bootstrap E-Commerce Template
 * Copyright 2023 Createx Studio
 * Theme styles
 */
 
// User variables
@import 'user-variables';

// Configuration & utilities
@import 'variables';
@import 'mixins';
@import 'utilities';

// Bootstrap
@import '../../dist/vendor/bootstrap/scss/bootstrap';

// Layout & components
@import 'reboot';
@import 'components';

// User custom styles
@import 'user';
